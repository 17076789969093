import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { GiHamburgerMenu } from 'react-icons/gi';
import Logo from '../Component/assets/Logo.png';
import dashbord from '../Component/assets/GraphDashbord.png'
import leads from '../Component/assets/GroupLeads.png'
import associates from '../Component/assets/Variant3.png'
import admissionS from '../Component/assets/Admissions.png'
import rewardS from '../Component/assets/Rewards.png'
import notificatioS from '../Component/assets/Notifications.png'
import Signout from '../Component/assets/Log-out.png'
import ad from '../Component/assets/ad.png'


import '../App.css'
import {useNavigate } from 'react-router-dom';
const Headers = () => {
    const Navigate = useNavigate()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <div>
            <section className='headermobile d-lg-none '>
                <div className='container-fluid'>
                    <div className='row  p-2'>
                        <div className='col-3 mt-1'>
                            <h3 onClick={handleShow}><GiHamburgerMenu /></h3>

                        </div>
                        <div className='col-9 d-flex justify-content-end'>
                            <img alt="Logo" src={Logo} style={{height:"4vh"}}/>
                        </div>
                    </div>
                </div>

            </section>


            {/* <section className='mobileview d-lg-none'> */}
                <Offcanvas show={show} onHide={handleClose} placement='start' >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                        <img alt="Logo" src={Logo} style={{height:"10vh", marginLeft:"8vh"}} />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="container-fluid example ">
                        <div className="flex-grow-1 p-1 mt-1 example " style={{ overflow: "auto" }}>
         
                <div className="row p-4" style={{ cursor: "pointer" }} >
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={dashbord} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => {
                      handleClose()
                    Navigate("/")
                    }}>Dashboard</h6>
                  </div>
                </div>
           
            
       
                <div className="row p-4 mt-1" style={{ cursor: "pointer" }} onClick={() => {
                  handleClose()
                Navigate("/leads")
                }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={leads} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt">Leads</h6>
                  </div>
                </div>
        

         
                <div className="row p-4 mt-1" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={associates} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => {
                      handleClose()
                       Navigate("/associate") 
                       }}>Associates</h6>
                  </div>
                </div>
         


                <div className="row p-4 mt-1" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={ad} alt='' style={{width:'35px'}}></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => {
                      handleClose()
                     Navigate("/admissions")
                     }}>Admissions</h6>
                  </div>
                </div>
        


       
                <div className="row p-4 mt-1" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={rewardS} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => {
                      handleClose()
                    Navigate('/rewards')
                    }}>Rewards</h6>
                  </div>
                </div>
           

         
                <div className="row p-4 mt-1" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={notificatioS} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => {

                      handleClose()
                    Navigate('/notification')
                    }}>Notification</h6>
                  </div>
                </div>
          
                <div className="row p-4 mt-1" style={{ cursor: "pointer" }} onClick={() => {
                  handleClose()
                  Navigate("/")
                  localStorage.removeItem('icc_token')
                  }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                  <img src={Signout} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt">Sign Out</h6>
                  </div>
                </div>
          
   {/* <div className="flex-grow-0 d-flex justify-content-between p-3" style={{cursor:"pointer"}} onClick={() => { Navigate("/Deepak") }}>
            <div className="d-flex  align-items-center justify-content-center " >
              <div
                style={{
                  borderRadius: "20%",
                  width: "50px",
                  height: "50px",
                }}
              >
              </div>
            </div>
           
            <div className="d-flex justify-content-center align-items-center p-3 ">
              <img alt="Logo" src={Logout} style={{ height: "20px" }} />
            </div>

          </div> */}

            {/* Repeat the above block for each menu item */}
          </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            {/* </section> */}
        </div>
    )
}

export default Headers

