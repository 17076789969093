import React, { useState } from 'react'
import eye from '../assets/solar_eye-linear.png';
import clas from '../assets/Frame 1171276071.svg';
import call from '../assets/Frame 1171276071 (1).svg';
import clander from '../assets/Frame 1171276071 (2).svg';
import degre from '../assets/Frame 1171276071 (3).svg';
import Currency from '../assets/mingcute_currency-rupee-fill.svg';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Admissions/Admission.css'
import { CiSearch } from 'react-icons/ci';
import { IoWalletSharp } from 'react-icons/io5';
import { GrLineChart } from "react-icons/gr";
import { TbDiscount, TbRosetteDiscountCheckFilled } from "react-icons/tb";
const Admissons = () => {
    const data = [
        {
          Sno:"1",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"2",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"3",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"4",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"5",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"6",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"7",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"8",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"9",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"10",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"11",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"12",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"13",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"13",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"13",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        {
          Sno:"13",
          name: "Ashish Meena",
          contact: "9826225894",
          class: "12th",
          Course: "BCA",
          Fees: "Ram jii",
          EMIs: "9854667102",
          FatherContactNo: "Prince",
          status: "Enrolled",
        },
        
        
      ];

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

  return (
    <>
      <section className='sticky-top' style={{backgroundColor:"white"}}>
        <div className="container">
              <div className="row pt-4 pb-3 sticky-top">
              <div className="col-lg-4 pt-2">
                    <h4 style={{color:"#66666",fontWeight:"600"}}>Admissons</h4>
                  </div>
                  <div className='col-lg-4'></div>
                <div className="col-lg-4 border d-flex justify-content-center align-items-center gap-3 p-2" style={{ borderRadius: "6px" }}>
                    <div className="">
                      <h6><CiSearch style={{ fontSize: "18px" }} /></h6>
                    </div>
                    <input className='d-flex justify-content-center align-items-center' style={{ width: "100%", outline: "none", border: "none" ,fontFamily:"sans-serif",color:"#B1AFC8"}} type="text" name='text' placeholder='Search Dashbord' onFocus={(e) => { e.target.style.border = 'none'; e.target.style.outline = 'none'; e.target.style.boxShadow = 'none'; }}/>
                  </div>
                  
              </div>
            </div>
          
      </section>
      <div className="container pb-5 mb-5" >
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12 ">
              <div className=" scroll" style={{ overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap'}}>
              <div className="" >
                <table class="table border" style={{borderRadius:"8px"}}>
                <thead>
                    <tr className='text-center'>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Contact No.</th>
                      <th>Class</th>
                      <th>Course</th>
                      <th>Fees</th>
                      <th>EMIs</th>
                      <th>Father's Contact No</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => {
                      return (
                        <tr key={index}>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.Sno}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.name}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.contact}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.class}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.Course}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.Fees}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.EMIs}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}>{row.FatherContactNo}</td>
                        <td className='text-center pt-4 pb-4' style={{ verticalAlign:"middle",color:"#6C7A90",fontWeight:"700",fontSize:"13px",fontStyle:"inter"}}> <span style={{color:"#34C759",fontWeight:"600"}}>{row.status}</span> </td>
                        <td className='pb-4 pt-4' style={{ verticalAlign: "middle", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif",cursor:"pointer" }} ><img src={eye} alt="" onClick={handleShow} /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
               
                </div>

                </div>
               </div>
              </div>
             </div>

       <Offcanvas show={show} placement="end" onHide={handleClose} style={{backgroundColor:"#616CD7"}} className="Scroll" >
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body >
         <div className="container deepak">
          <div className="row">
          <div className="col-3"></div>
            <div className="col-5 profile-back d-flex align-items-center justify-content-center"  style={{backgroundColor:"#FFCC00",}}> 
             <div className="">
             <div className="profile-picture" style={{border:"3.25px solid #E2E8F0"}}>
                <h1 className="profile-initial">D</h1>
              </div>
             </div>
            </div>
            <div className="col-3"></div>
            <div className="text-center text-white pt-4">
              <h4 style={{fontSize:"24px",lineHeight:"20px"}}>Deepak Choudhary</h4>
              <h6 className='text-truncate' style={{fontSize:"11px"}}>@deepakmahala661@gmail.com</h6>
            </div>
          </div>
          <div className="row mt-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6 pt-3 text-white">
                <h6><img src={clas} alt="" style={{border:"2px solid white" ,borderRadius:"100px",width:"35px"}}/> 12th Class</h6>
              </div>
              <div className="col-6 pt-3 text-white">
                <h6><img src={call} alt="" style={{border:"2px solid white" ,borderRadius:"100px",width:"35px"}}/> 9826225745</h6>
              </div>
              <div className="col-6 pt-4 text-white">
                <h6><img src={clander} alt="" style={{border:"2px solid white" ,borderRadius:"100px",width:"35px"}}/> 03/10/2005</h6>
              </div>
              <div className="col-6 pt-4 text-white">
                <h6><img src={degre} alt="" style={{border:"2px solid white" ,borderRadius:"100px",width:"35px"}}/> BCA AI/ML</h6>
              </div>
            </div>
          </div>
          </div><hr className='text-white'/>
          <div className="row">
            <div className="col-12">
            <div className="card">
            <div className="card-body">
              <div className="" >
                <div className="d-flex gap-2" ><h6>Gender :</h6> <span> Male</span></div>
                <div className="d-flex gap-2"><h6>City :</h6> <span>Jaipur, Rajasthan</span></div>
                <div className="d-flex gap-2"><h6>Address:</h6><span>28 Vaishali Nagar, Chanda Tower Gandhi path Road</span></div>
                <div className="d-flex gap-2"><h6>Father’s Name : </h6> <span>Ashish Sigh</span> </div>
                <div className="d-flex gap-2"><h6>Father’s Contact No. : </h6> <span>9854632170</span> </div>
              </div><hr />
            </div>
            <div className="row p-2" style={{marginTop:"-30px"}}>
          <div className="col-12">
            <div className="row">
              <div className="col-6 pt-2">
                <h6><img src={Currency} alt="" style={{border:"2px solid white" ,borderRadius:"100px",width:"35px"}}/> 12th Class</h6>
              </div>
              <div className="col-6 pt-2">
                <h6><IoWalletSharp style={{fontSize:"30px"}}/> 9826225745</h6>
              </div>
              <div className="col-6 pt-2">
                <h6><GrLineChart style={{fontSize:"20px"}}/> 03/10/2005</h6>
              </div>
              <div className="col-6 pt-2">
                <h6><TbDiscount style={{fontSize:"25px"}}/> BCA AI/ML</h6>
              </div>
            </div>
          </div>
          </div>
          <div className="ms-2 d-flex gap-2 pb-2">
            <h6><TbRosetteDiscountCheckFilled style={{fontSize:"25px",color:"#23D406"}}/>Documents:</h6> <span>Completed</span>
          </div>
          </div>
            </div>
          </div>
         </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Admissons
