import React, { useState } from 'react';
import edit from '../assets/edit1.png';
import modelimg from '../assets/modelimg1.png';
import './Associate.css';
import { Button, Modal, Box } from '@mui/material';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Navigate, useNavigate } from 'react-router-dom';

const AssociateData = () => {
  const Navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(null);

  const togglePopup = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    outline: 'none',
  };

  const data = [
    {
      Name: "Mukesh Meena",
      Contect: "7976808381",
      Class: "12th",
      Gender: 0,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Bharti Shekhawat",
      Contect: "7976808381",
      Class: "12th",
      Gender: 1,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Mukesh Meena",
      Contect: "7976808381",
      Class: "12th",
      Gender: 0,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Bharti Shekhawat",
      Contect: "7976808381",
      Class: "12th",
      Gender: 1,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Mukesh Meena",
      Contect: "7976808381",
      Class: "12th",
      Gender: 0,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Bharti Shekhawat",
      Contect: "7976808381",
      Class: "12th",
      Gender: 1,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Mukesh Meena",
      Contect: "7976808381",
      Class: "12th",
      Gender: 0,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Bharti Shekhawat",
      Contect: "7976808381",
      Class: "12th",
      Gender: 1,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Mukesh Meena",
      Contect: "7976808381",
      Class: "12th",
      Gender: 0,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },
    {
      Name: "Bharti Shekhawat",
      Contect: "7976808381",
      Class: "12th",
      Gender: 1,
      Father: "Jun 16, 2024",
      Fatherscontect: "7728817156",
      Institution: "TIPS-G",
      Status: "pending"
    },

  ];

  return (
    <>
       <div className="container mt-3 sticky-top" style={{backgroundColor:"white"}}>
        <div className="row">
          <div className='d-flex justify-content-between '>
            <div>  
              <h2 onClick={() => Navigate('/associate/profile/1')}><IoMdArrowRoundBack className='p-1' style={{ backgroundColor: "#F1F5F9", borderRadius: "50px", cursor: "pointer" }} /></h2>
            </div>
            <div className="pt-2">
              <h4>Associate Data</h4>
            </div>
            <div>
            <Button variant="contained" onClick={handleOpen}>Update</Button>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-12 com-md-6 col-sm-12">
              <div className="scroll" style={{height:"80vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                <div className="">
                  <table className="table border" style={{ borderRadius: "8px" }}>
                    <thead className='border'>
                      <tr>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Name</th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Contact No.</th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Class</th>
                        <th className='text-center' style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Gender</th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Father Name</th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Father Contact No.</th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Institution </th>
                        <th style={{ fontFamily: "sans-serif", fontWeight: "600" }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, index) => (
                        <tr key={index}>
                          <td className=' pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Name}</td>
                          <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Contect}</td>
                          <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Class}</td>
                          <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Gender === 0 && 'Male'} {row.Gender === 1 && 'Female'}</td>
                          <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Father}</td>
                          <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Fatherscontect}</td>
                          <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030229", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Institution}</td>
                          <td className="pb-4 pt-4 popup-dropdown-container popup-button" onClick={() => togglePopup(index)} style={{ verticalAlign: "middle", color: "#FFCC00", fontWeight: "400", fontSize: "19px", fontFamily: "sans-serif", cursor: "pointer" }}>
                            {row.Status} <img src={edit} alt="" />
                            {activeIndex === index && (
                              <div className="popup-content" onClose={handleClose}>
                                <div className="popup-item" style={{ color: "green" }}>Approved</div>
                                <div className="popup-item" style={{ color: "red" }}>Rejected</div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" >
        <Box sx={style} style={{ borderRadius: "20px" }}>
          <div className="text-center">
            <div className="mt-3 mb-3">
              <img src={modelimg} alt="" />
            </div>
            <h5 className='mb-3'>Status Updated Successfully</h5>
          </div>
        </Box>
      </Modal>

    </>
  );
}

export default AssociateData;
