import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDashboardCustomize } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { FaShoppingBag } from "react-icons/fa";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { MdOutlineGraphicEq } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import "../App.css";
import bg from "./assets/bg.png";

const Five = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const data = [
    {
      Sno: "1",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "2",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "3",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "4",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "5",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "6",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "7",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "8",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "9",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
    {
      Sno: "10",
      name: "Ashish Meena",
      contact: "9826225894",
      class: "12th",
      gender: "Male",
      fatherName: "Ram jii",
      fatherContact: "9854667102",
      institution: "Prince",
      status: "Follow Up",
    },
  ];

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-2 col-md-6 col-sm-12"
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <div className="mt-3">
                <img src={bg} alt="" style={{ width: "10vw" }} />
              </div>
              <div className="mt-5 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <MdDashboardCustomize
                    style={{ marginRight: "10px", fontSize: "25px" }}
                  />
                  Dashboard
                </button>
              </div>
              <div className="mt-4 ">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <div className="mr-5">
                    <MdOutlineGraphicEq
                      className=""
                      style={{ marginRight: "10px", fontSize: "20px" }}
                    />
                    <span>Leads</span>
                  </div>
                </button>
              </div>
              <div className="mt-4 mr-2">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <BsFillFileEarmarkPersonFill
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Asscociates
                </button>
              </div>
              <div className="mt-4 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <FaShoppingBag
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Admissions
                </button>
              </div>
              <div className="mt-4 mr-4">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <SlBadge style={{ marginRight: "10px", fontSize: "20px" }} />
                  Rewards
                </button>
              </div>
              <div className="mt-4 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <RiMessage2Line
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Notification
                </button>
              </div>
              <div className="mt-4 mr-4" style={{ marginBottom: "50%" }}>
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <IoIosLogOut
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Sign Out
                </button>
              </div>
            </div>

            <div className="col-lg-10 col-md-6 col-sm-12">

              {/* 
              <div className="row mt-5 align-item-center">
                <div className="col-lg-1 col-md-6 col-sm-12">
                  <button style={{ borderRadius: "20px", border: "none" }}>
                    <FaArrowLeft />
                  </button>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-12"></div>

                <div className="col-lg-4 col-md-3 col-sm-6 d-flex justify-content-center">
                  <div className="d-flex ml-3 ">
                    <button
                      variant="outline-secondary"
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "none",
                        backgroundColor: "#EFEFEF",
                        borderRadius: "10px",
                        marginLeft: "220px",
                      }}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                      <FaFilter className="m-1 mr-1" />
                      Filter
                    </button>
                  </div>
                  {isFilterOpen && (
                    <div
                      className="filter-popup mt-5"
                      style={{ marginLeft: "200px" }}
                    >
                      <button className="filter-button">New</button>
                      <button className="filter-button">Interested</button>
                      <button className="filter-button">Follow Up</button>
                      <button className="filter-button">
                        Follow Up Needed
                      </button>
                      <button className="filter-button">In Progress</button>
                      <button className="filter-button">Enrolled</button>
                    </div>
                  )}
                </div>
              </div> */}

              <div className="row mt-4">
                <div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: "start" }}>
                  <div className="mt-2 ml-4">
                    <button style={{ borderRadius: "20px", border: "none" }}>
                      <FaArrowLeft />
                    </button>

                  </div>
                </div>



                <div className="col-lg-6 col-md-6 col-sm-12 " style={{ textAlign: "end" }}>

                  <div className="mr-5">
                    <button variant="outline-secondary" style={{
                      width: "100px",
                      height: "40px",
                      border: "none",
                      backgroundColor: "#EFEFEF",
                      borderRadius: "10px",
                      marginLeft: "220px",
                    }}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                      <FaFilter className="m-1 mr-1" />
                      Filter
                    </button>
                  </div>
                  {isFilterOpen && (
                    <div
                      className="filter-popup mt-5"
                      style={{ marginLeft: "200px" }}
                    >
                      <button className="filter-button">New</button>
                      <button className="filter-button">Interested</button>
                      <button className="filter-button">Follow Up</button>
                      <button className="filter-button">
                        Follow Up Needed
                      </button>
                      <button className="filter-button">In Progress</button>
                      <button className="filter-button">Enrolled</button>
                    </div>
                  )}
                </div>
              </div>



              <div className="container-fluid" >
                <div className="row mt-4">
                  <div className="col-lg-12 com-md-6 col-sm-12 ">
                    <div className=" h-100" style={{ overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>

                      <div className="" >
                        <table class="table border" style={{ borderRadius: "8px" }}>
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <th>Name</th>
                              <th>Contact No.</th>
                              <th>Class</th>
                              <th>Gender</th>
                              <th>Father Name</th>
                              <th>Father Contact No.</th>
                              <th>Institution</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => {
                              return (
                                <tr key={index}>
                                  <td className='text-center' style={{ padding: "10px", verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.Sno}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>
                                    <a href="#">{row.name}</a>
                                  </td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.contact}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.class}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.gender}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.fatherName}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.fatherContact}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>{row.institution}</td>
                                  <td className='text-center' style={{ verticalAlign: "middle", color: "#6C7A90", fontWeight: "700", fontSize: "13px", fontStyle: "inter" }}>
                                    <span style={{ color: "#FFCC00", fontWeight: "600" }}>{row.status}</span>
                                    <span className="edit-icon">
                                      <MdModeEdit
                                        style={{ color: "brown", fontSize: "20px" }}
                                      />
                                    </span>
                                  </td>
                                </tr>

                              )

                            })}

                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{ textAlign: "center", marginTop: "40px" }}>
                <button className="button-style mr-2 m-1">
                  <FaChevronLeft />
                </button>
                <button className="button-style mr-2 m-1">1</button>
                <button className="button-style mr-2 m-1">2</button>
                <button className="button-style mr-2 m-1">3</button>
                <button className="button-style mr-2 m-1">
                  <FaChevronRight />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Five;
