import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdModeEdit } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { FaCalendarAlt, FaFilter } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { Form, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useRef } from 'react';
import "../Leads/Leads.css";
import { Pagination, Stack } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Navigate, useNavigate } from "react-router-dom";
import { baseUrl } from "../../env";
import { Toaster, toast } from 'sonner'


const Popup = ({ visible, onClose, getLeads, setstatus, status, endDate, startDate }) => {
  const [leadType, setLeadType] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]); // Filtered leads based on selected status
  const [selectedStatus, setSelectedStatus] = useState(null);
  const getLeadTypes = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(`${baseUrl}/admin/lead/lead-types`, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          setLeadType(result.studentTypes); // Assuming studentTypes is correct
          console.log('Lead types fetched successfully:', result.studentTypes);
        } else {
          console.log('API response success false:', result.message);
        }
      } else {
        console.error('Failed to fetch lead types:', response.statusText);
      }
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };

  // Fetch all leads


  useEffect(() => {
    getLeadTypes();
    getLeads()
  }, [status, endDate, startDate]);

  const handleStatusSelect = async (statusArray) => {
    const status = statusArray;  // or another method to select a specific status if needed
    setstatus(status);
    const filtered = leadType.filter(lead => lead.status === status);
    console.log("Filtered:", filtered);
    setFilteredLeads(filtered);
    await getLeads(status);
  };
  // Example filter function


  return (
    <div className={`popup ${visible ? "show" : ""}`}>
      <button
        className="close-btn"
        style={{ border: "none" }}
        onClick={onClose}
      >
        <MdCancel />
      </button>
      <div className="content">
        {leadType.map((status, index) => (
          <div key={index}>
            <p
              onClick={() => handleStatusSelect(status)}  // handle status selection
              style={{ cursor: "pointer" }}  // Make it look clickable
            >
              {status}
            </p>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

const Leads = () => {

  const Navigate = useNavigate();
  const [selectReamark, setSelectRemark] = useState("");
  const [emiError, setEmiError] = useState("");
  const [fees, setFees] = useState('');
  const [emiCount, setEmiCount] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [discount, setDiscount] = useState("");
  const [showCourse, setShowCourse] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setstatus] = useState("");
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [status1, setstatus1] = useState('New');
  const [data, setData] = useState('');



  useEffect(() => {
    status_update();
  }, []);


  const status_update = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "student_id": 1,
        "status": status1
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      const response = await fetch("https://iccdev.tipsg.in/api/v1/admin/lead/update-status", requestOptions)
      const result = await response.json();
      if (result.success === true) {
        setData(result);
      }
    } catch (error) {
      console.log("Error", error)

    }
  }
  const defaultStartDate = '2024-01-01';
  const defaultEndDate = '2024-12-31';


  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleClose = () =>{ 
    setShow(false);
    setEmiError("");
    setFees("");
    setEmiCount("");
    setEmiAmount("");
    setSelectedCourse("");
    setDiscount("");
  }
  const handleShow = () => setShow(true);



  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleEditClick = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle dropdown
  };


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [leadsData,setLeadsData] = useState([])

  const getLeads = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);

      const raw = JSON.stringify({
        "fromDate": startDate,
        "toDate": endDate,
        "status": status
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`https://iccdev.tipsg.in/api/v1/admin/lead/list?page=${currentPage}&limit=${limit}&search=${encodeURIComponent(search)}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success == true) {
            setLeadsData(result.data.students);
            setTotalPages(result.data.totalPages)
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error)
    }
  }



// Get Courses details data
  const getCourses = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);

     
      
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"   
       
      };

      fetch(`${baseUrl}/admin/lead/courses`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success == true) {
            setShowCourse(result?.courses);
           
          }
          else {
            toast(result.error)
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error)
    }
  }

  // Update leads data on enrolled students 
  const updateEnrolledStaus = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);

      const raw = JSON.stringify({
        student_id: "",
  status: "Enrolled",
  course_id: "",
  fee_amount: "",
  recieved_amount: "",
  emi_count: "",
      }); 
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"   
      };

      fetch(`${baseUrl}/admin/lead/update-status`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success == true) {
            handleClose();
           toast(result?.msg);
          }
          else {
            toast(result?.error)
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    if (selectedCourse !== "") {
      const selected = showCourse.find((course) => course.course_name === selectedCourse);
      if (selected) {
        setFees(selected.offer_price); // set fees as the offer price from the API
      }
    }
  }, [selectedCourse]);
  
  // Update fees dynamically based on the discount value
  useEffect(() => {
    if (discount !== "" && !isNaN(discount)) {
      const initialFees = selectedCourse ? showCourse.find((course) => course.course_name === selectedCourse)?.offer_price : 0;
      const discountedFees = initialFees - (initialFees * discount) / 100;
      setFees(discountedFees.toFixed(2)); // Updating fees dynamically
    }
  }, [discount]);

    // Update EMI amount dynamically based on the entered EMI count
  useEffect(() => {
    if (emiCount !== "" && !isNaN(emiCount)) {
      const numEmi = Math.min(emiCount, 30); // Ensure EMI count does not exceed 30
      const emiAmountValue = fees / numEmi;
      setEmiAmount(emiAmountValue.toFixed(2)); // Update EMI amount
    } else {
      setEmiAmount(""); // Clear EMI amount if EMI count is invalid
    }
  }, [emiCount, fees]);

  // Handle EMI count input change
  const handleEmiCountChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= 30 && Number(value) > 0)) {
      setEmiCount(value);
      setEmiError(""); // Clear error message if value is valid
    } else {
      setEmiError("Maximum EMI count is 30"); // Set error message if value exceeds 30
    }
  };

  useEffect(()=>{
    getLeads();
  }, [currentPage, status, startDate, endDate])

  console.log(leadsData)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handleApply = () => {
    const appliedStartDate = startDate || defaultStartDate;
    const appliedEndDate = endDate || defaultEndDate;
    getLeads()
    console.log('Date Range:', appliedStartDate, 'to', appliedEndDate);
    // Perform any actions with the selected date range
    setIsOpen(false); // Close the dropdown after applying
  };


  return (
    <>
         <Toaster richColors position="top-right" visibleToasts='1' />

      <section>
        <div className="container-fluid">
          <div className="row" style={{ backgroundColor: "white" }}>
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="row align-items-center mt-4 sticky-top" style={{ backgroundColor: "white" }}>

                <div className="col-3 col-md-1 d-flex gap-5">
                  <h2><IoMdArrowRoundBack onClick={() => Navigate(-1)} className='p-1' style={{ backgroundColor: "#F1F5F9", borderRadius: "50px", cursor: "pointer" }} /></h2>


                  <h4 className="text-center mt-2" style={{ color: "#66666", fontWeight: "600" }}>Leads</h4>
                </div>
                <div className="col-5 col-md-7 text-center ">
                  <div style={{ color: "#000000", fontSize: "24px", fontWeight: "500", }}></div>
                </div>
                <div className="col-4 col-md-4  d-flex justify-content-end">
                  <div>
                    <FaCalendarAlt className="mt-2" onClick={toggleDropdown} style={{ cursor: 'pointer', fontSize: '24px' }} />

                    {isOpen && (
                      <div ref={dropdownRef} className="dropdown">
                        <div className="mt-2">
                          <label htmlFor="startDate" className="dropdown-label" style={{ textAlign: "center" }}>Start Date:</label>
                          <input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} className="dropdown-input"
                          />
                        </div>

                        <div>
                          <label htmlFor="endDate" className="dropdown-label" style={{ textAlign: "center" }}>End Date:</label>
                          <input type="date" id="endDate" value={endDate} onChange={handleEndDateChange} className="dropdown-input"
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Button onClick={handleApply} className="btn btn-primary dropdown-button" >Apply</Button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="">
                    <button className="" variant="outline-secondary" onClick={togglePopup} style={{ width: "100px", height: "40px", border: "none", backgroundColor: "#EFEFEF", borderRadius: "10px", marginLeft: "10px", }} >
                      <Popup visible={isPopupVisible} onClose={togglePopup} getLeads={getLeads} setstatus={setstatus} currentPage={currentPage} status={status} startDate={startDate} endDate={endDate} />
                      <FaFilter className="m-1 mr-1" visible={isPopupVisible} onclose={togglePopup} />
                      Filter
                    </button>
                  </div>
                </div>
              </div>

              <div className="container-fluid mb-4">
                <div className="row mt-4">
                  <div className="col-lg-12 com-md-6 col-sm-12 ">
                    <div className="scroll " style={{ height: "78vh", overflowX: "scroll", overflowY: "scroll", textWrap: "nowrap", }} >
                      <div>
                        <table className="table border text-center" style={{ borderRadius: "8px" }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Contact No.</th>
                              <th>Class</th>
                              <th>Gender</th>
                              <th>Father Name</th>
                              <th>Father Contact No.</th>
                              <th>Institution</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leadsData.map((row, index) => (
                              <tr key={index}>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#0A40E2", fontWeight: "600", fontSize: "16px", fontStyle: "inter", lineHeight: "24px", }}>{row.full_name}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.mobile_number}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.class_name}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.gender}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.father_name}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.father_contact}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>{row.institute_name}</td>
                                <td className="text-center pb-4 pt-4" style={{ verticalAlign: "middle", color: "#030229", fontWeight: "500", fontSize: "13px", fontStyle: "inter", }}>
                                  <div className="d-flex align-items-center position-relative">
                                    <span className="ml-5" style={{ color: "#FFCC00", fontWeight: "600", }}>{row.status}</span>
                                    {openIndex === index && (
                                      <div className="filter-popup" style={{ border: "none", borderRadius: "10px", position: "absolute", left: "-200px", top: "100%", zIndex: 1000, marginLeft: "120px",}}>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>New</button>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>Interested</button>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>Uninterested</button>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>Follow Up Needed</button>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>In Progress </button>
                                        <button className="filter-button" style={{backgroundColor: "transparent", border: "none", color: "#737791",fontWeight: "500", fontSize: "14px", lineHeight: "21px", height: "21px",}}>
                                          <span variant="primary" 
                                          onClick={() => {
                                            handleShow();
                                            getCourses();
                                          }}>Enrolled</span>

                                          <div className="col-lg-4 col-md-6 col-sm-12"></div>

                                          <div className="col-lg-4 col-md-6 col-sm-12">
                                            <Offcanvas show={show} onHide={handleClose} placement="end">
                                              <Offcanvas.Body style={{ backgroundColor: "#6b6bff", }}>
                                                <button onClick={handleClose} className="close-btn" style={{ border: "none" }}>
                                                  <MdCancel className="mb-2" style={{ color: "#fff" }} />
                                                </button>
                                                <div className="" style={{ border: "none" }}>
                                                  <Form className="custom-form" style={{ backgroundColor: "#6b6bff", }}>
                                                    <Form.Group controlId="formCourse">
                                                      <Form.Label style={{ color: "#fff", fontWeight: "200", marginRight: "250px",}}>Course<span className="required">*</span></Form.Label>
                                                      <Form.Control 
                                                     as="select" 
                                                     value={selectedCourse} 
                                                     onChange={(e) => setSelectedCourse(e.target.value)}
                                                     placeholder="Select The Course"
                                                      >
                                                        {selectedCourse === "" && <option value="">Select The Course</option>}
            {showCourse.map((res, key) => (
              <option key={key} value={res.course_name}>{res?.course_name}</option>
            ))}
                                                        
                                                      </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId="formFees">
                                                      <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                          <Form.Label style={{color: "#fff", fontWeight: "200", marginRight: "100px",}}>Fees</Form.Label>
                                                          <Form.Control 
                type="text" 
                placeholder="Total Amount" 
                value={fees} 
                readOnly 
              />
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                          <Form.Label style={{ color: "#fff", fontWeight: "200", marginRight: "80px",}}>Discount<span className="required">*</span></Form.Label>
                                                          <Form.Control
                  type="text"
                  placeholder="in %"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
                                                        </div>
                                                      </div>
                                                    </Form.Group>
                                                    <Form.Group controlId="formEMI">
                                                      <Form.Label style={{ color: "#fff", fontWeight: "200", marginRight: "180px",}}>No. of EMI<span className="required">*</span></Form.Label>
                                                      <Form.Control
                type="number"
                placeholder="Enter EMI"
                value={emiCount}
                onChange={handleEmiCountChange}
                max={30} // Set maximum EMI count
              />
               {emiError && <p style={{ color: "white", margin: "5px 0", fontSize: "14px" }}>* {emiError}</p>}
                                                    </Form.Group>
                                                    <Form.Group controlId="formEMIAmount">
                                                      <Form.Label style={{ color: "#fff", fontWeight: "200", marginRight: "160px",}}>EMI Amount <span className="required">*</span></Form.Label>
                                                      <Form.Control type="text" placeholder="Enter Amount" value={emiAmount} readOnly />
                                                    </Form.Group>
                                                    <Form.Group controlId="formRemark">
                                                      <Form.Label style={{ color: "#fff",fontWeight: "200", marginRight: "250px", }}>Remark<span className="required">*</span></Form.Label>
                                                      <Form.Control as="textarea"
                                                       rows={3} 
                                                       placeholder="Enter Remark"
                                                       value={selectReamark}
                                                       onChange={(e) => setSelectRemark(e.target.value)}
                                                       />
                                                    </Form.Group>
                                                    <Button onClick={updateEnrolledStaus} className="mt-2 mb-4" type="submit" style={{ backgroundColor: "#23D406", width: "100%",  border: "1px", borderRadius: "10px", gap: "10px",}}>Save</Button>
                                                  </Form>
                                                </div>
                                              </Offcanvas.Body>
                                            </Offcanvas>
                                          </div>
                                          <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                        </button>
                                      </div>
                                    )}

                                    <span className="edit-icon" variant="outline-secondary" onClick={() => handleEditClick(index)} style={{ cursor: "pointer", }}>
                                      <MdModeEdit style={{ color: "brown", fontSize: "20px", }} />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>

                      </div>
                    </div>
                    <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center">
                      <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Leads;
