import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { MdDashboardCustomize } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { FaShoppingBag } from "react-icons/fa";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { MdOutlineGraphicEq } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { FaSearch } from 'react-icons/fa';
import "../App.css";
import bg from "./assets/bg.png";
import circle from "./assets/circle.jpg"

const Profile1 = () => {



  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-2 col-md-6 col-sm-12"
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <div className="mt-3">
                <img src={bg} alt="" style={{ width: "10vw" }} />
              </div>
              <div className="mt-5 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <MdDashboardCustomize
                    style={{ marginRight: "10px", fontSize: "25px" }}
                  />
                  Dashboard
                </button>
              </div>
              <div className="mt-4 ">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <div className="mr-5">
                    <MdOutlineGraphicEq
                      className=""
                      style={{ marginRight: "10px", fontSize: "20px" }}
                    />
                    <span>Leads</span>
                  </div>
                </button>
              </div>
              <div className="mt-4 mr-2">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <BsFillFileEarmarkPersonFill
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Asscociates
                </button>
              </div>
              <div className="mt-4 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <FaShoppingBag
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Admissions
                </button>
              </div>
              <div className="mt-4 mr-4">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <SlBadge style={{ marginRight: "10px", fontSize: "20px" }} />
                  Rewards
                </button>
              </div>
              <div className="mt-4 mr-1">
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <RiMessage2Line
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Notification
                </button>
              </div>
              <div className="mt-4 mr-4" style={{ marginBottom: "50%" }}>
                <button
                  className="button-style"
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  <IoIosLogOut
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  Sign Out
                </button>
              </div>
            </div>

            <div className="col-lg-10 col-md-6 col-sm-12">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
                    <div className="search-bar ml-3">
      <FaSearch className="search-icon" />
      <input 
        type="text" 
        placeholder="    Search Dashboard" 
        className="search-input" />
    </div>
  </div>
  <div className="col-lg-9 col-md-6 col-sm-12"></div>
                </div>






                <div className="row mt-5">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 ml-5">
                          <img src={circle} alt="" style={{width:"100%"}}/>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                          <p>Vishaka Shekhawat</p>
                          <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{color:"#8A8A8A"}}>Mentor</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="big-dot">Prince IMS</div>

                        </div>
                      </div>

                        </div>
                        
                      </div>
                     
                      
                    
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">Mukesh</div>
                  <div className="col-lg-4 col-md-6 col-sm-12">Mukesh</div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile1;
