import React, { useState } from 'react'
import LogoFrist from '../assets/LogoFrist.svg'
import OTPInput, { ResendOTP } from "otp-input-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginForm.css';
import { baseUrl } from '../../env';
import { useNavigate } from 'react-router-dom';

import { Toaster, toast } from 'sonner'

const Login = () => {

    const [OTP, setOTP] = useState("");

    const handleInputChange = (e) => {
        setNumber(e.target.value);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the verification logic here
      };

    const [number, setNumber] = useState('');
    const [step, setStep] = useState(1);
    const [orderId, setOrderId] = useState('')

    const getOtp = async () => {

        if (number.length !== 10) {
           toast.error('Number must be exactly 10 digits long');
        } 

        const header = new Headers();
        header.append('Content-Type', 'application/json');
    
        const reQoptions = {
            redirect: "follow",
            method: 'POST',
            body: JSON.stringify({
                "contact_no": "91" + number
            }),
            headers: header
        };
    
        try {
            const response = await fetch(`${baseUrl}/admin/auth/request-login`, reQoptions);
            const result = await response.json();
            if (result.success==true) {
                toast.success('Otp sended successfully')
                setOrderId(result.otpResult.orderId)
                setStep(2)
            }
            else if(result.error=='Mobile number is not registered'){
                toast.error(result.error)
            }

            else{
                console.log(result.error)
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };


    
    const navigate = useNavigate()
    const verify = async () => {

        if (OTP.length !== 6) {
            toast.error('OTP must be exactly of 6 digits');
            return;
        }

        const header = new Headers();
        header.append('Content-Type', 'application/json');
    
        const reQoptions = {
            redirect: "follow",
            method: 'POST',
            body: JSON.stringify({
                "contactNo": "91" + number,
                "orderId":orderId,
                "otp":OTP,
            }),
            headers: header
        };
    
        try {
            const response = await fetch(`${baseUrl}/admin/auth/verify-login-otp`, reQoptions);
            const result = await response.json();
            
            if (!response.ok) {
                toast.error(result.error);
              }
            else {
                toast.success('Verified login');
                console.log('this is response', result.token);
                localStorage.setItem('icc_token', result.token);
                setTimeout(()=>{
                    navigate("/");
                },1500)
              }
            
            
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
   
    return (
        <>
     
     <Toaster richColors position="top-right" visibleToasts='1' />

    <div className="container-fluid p-0 m-0 w-100"  style={{height:"100vh"}}>

      <div className="row h-100">


        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white">
        <div className=''>
         <img src={LogoFrist} alt="" style={{ width: '100%' }} />
           </div>
        </div>



        <div className="col-lg-6 h-100 d-flex justify-content-center align-items-center" style={{backgroundColor:"#616CD7"}}>
          <form onSubmit={handleSubmit} className=" p-4 rounded">
          {
            step === 1?<>

            <div className="form-group">
              <h6 className='text-white'>Enter Your Number</h6>
              <input type="text" className="form-control" value={number} onChange={handleInputChange} placeholder=''/>
            </div>
            </>:<></>
          }



{
    step === 1 ? <>
    <button onClick={getOtp} type="submit" className="btn btn-primary w-100 mt-3">Send OTP</button>
    </> : <>
    
    <p className=' text-white' >Check OTP on <span style={{textDecoration:'underline'}} >{number}</span></p>
          <div className="d-flex justify-content-between mt-3" style={{borderRadius:"10px"}}>
              <OTPInput className="otp-class" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
              
            </div>
            <button onClick={verify} type="submit" className="btn btn-primary w-100 mt-3">Verify</button>
    </>
}

          </form>
        </div>
      </div>
    </div>
        </>
    )
}

export default Login
