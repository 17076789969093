import React from 'react'
import '../../src/Sidebar.css'
import Logo from '../Component/assets/Logo.png'
import {useNavigate } from 'react-router-dom'
import dashbord from '../Component/assets/GraphDashbord.png'
import leads from '../Component/assets/GroupLeads.png'
import associates from '../Component/assets/Variant3.png'
import admissionS from '../Component/assets/Admissions.png'
import rewardS from '../Component/assets/Rewards.png'
import notificatioS from '../Component/assets/Notifications.png'
import Signout from '../Component/assets/Log-out.png'
import ad from '../Component/assets/ad.png'



const Sidebar = () => {
  const Navigate = useNavigate()
//   let Path = location.pathname;
  // let access_control = localStorage.getItem("access_control").split(",");
//   let access_control = JSON.parse(localStorage.getItem("access_control"));
//   console.log(access_control.includes('associate'))

  return (
    <>
      <div className='scroll'>
        <div className=" main-sidee d-flex flex-column">
          <div className="mt-4 ml-6 flex-grow-0 d-flex justify-content-center">
            <img alt="Logo" src={Logo} style={{height:"10vh"}} />
          </div>
          <div className="flex-grow-1  mt-3 example scroll" style={{ overflowY: "auto" ,overflowY:"auto"}}>
            {/* {
              access_control.includes('dashboard') ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }} >
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={dashbord} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => (Navigate("/"))}>Dashboard</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}

            {/* {
              access_control.includes("target management") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={leads} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => { Navigate("/leads") }}>Leads</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}


            {/* {
              access_control.includes("admin") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={associates} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => (Navigate("/associate"))}>Associates</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}


            {/* {
              access_control.includes("operator") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={ad} alt='' style={{width:"35px"}}></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => (Navigate('/admissions'))}>Admissions</h6>
                  </div>
                </div>
                
              {/* </> : <></>
            } */}

            {/* {
              access_control.includes("team leader") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={rewardS} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt" onClick={() => (Navigate('/rewards'))}>Rewards</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}

            {/* {
              access_control.includes("incentives") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }} onClick={() => (Navigate("/notification"))}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={notificatioS} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt">Notification</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}

           

            {/* {
              access_control.includes("case status") ? <> */}
                <div className="row p-4" style={{ cursor: "pointer" }} onClick={() => { Navigate("/login");localStorage.removeItem('icc_token') }}>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    <img src={Signout} alt=''></img>
                  </div>
                  <div className="col-9 d-flex justify-content-start">
                    <h6 className="pt-1 hover-textt">Sign Out</h6>
                  </div>
                </div>
              {/* </> : <></>
            } */}
          </div>
         
        </div>
      </div>

    </>
  )
}

export default Sidebar

