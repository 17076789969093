import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const Privatee = () => {

  const icc_token = localStorage.getItem('icc_token');
  console.log(icc_token)

    if(icc_token) {
        return <Outlet/>
    }
    else{
        return <Navigate to={'/login'}/>
    }
  
}

export default Privatee
