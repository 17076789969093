import React, { useState } from 'react'
import { FaRegFileAlt, FaUserPlus } from 'react-icons/fa';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { VscGraph } from "react-icons/vsc";
import '../Dashbord/Dashbord.css';
import ReactApexChart from 'react-apexcharts';
import { Button } from '@mui/material';

const Dashbord = () => {

    const [options, setOptions] = useState({
        chart: {
          height: 350,
          type: 'line',
        },
        forecastDataPoints: {
          count: 7
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001','4/11/2001' ,'5/11/2001' ,'6/11/2001'],
          tickAmount: 10,
          labels: {
            formatter: function(value, timestamp, opts) {
              return opts.dateFormatter(new Date(timestamp), 'dd MMM');
            }
          }
        },
        title: {
          text: 'Forecast',
          align: 'left',
          style: {
            fontSize: "16px",
            color: '#666'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        }
      });
    
      const [series, setSeries] = useState([{
        name: 'Sales',
        data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
      }]);
    

  return (
    <>
    <div className="pt-4   sticky-top" style={{backgroundColor:"white"}}>
        <h3>Dashbord</h3>
      </div>
      <div className="container  pb-5">
      {/* <div className=" scroll" style={{height:"75vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}> */}
      <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12">
             <div className="card crd-shdo" style={{borderRadius:"20px",border:"none"}}>
                <div className="card-body">
                <div className="row pt-3 d-flex justify-content-between">
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                        <h6 className='for-mobile'>Hii Welcome Back</h6>
                        <h3 style={{fontWeight:"600"}}>Sales Summery</h3>
                    </div>
                    {/* <div className="col-6"></div> */}
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center justify-content-center gap-2 ">
                    <div className="">
                        <img src="https://b2912710.smushcdn.com/2912710/wp-content/uploads/2023/10/IMAG15-cover-1024x723.png?lossy=2&strip=1&webp=1" alt="" style={{width:"50px",borderRadius:"100%",height:"50px"}} />
                    </div>
                        <div className="">
                        <h6>Deepak Choudhary</h6>
                        <h6 className='ms-2'>Admin</h6>
                        </div>
                    </div>
                </div>
                    <div className="row d-flex  align-items-center justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-12 p-5 pb-4 mb-2">
                           <div className="card"style={{borderRadius:"20px",backgroundColor:"#FFE2E5", border:"none"}}>
                            <div className="card-body">
                               <div className="pb-2">
                               <VscGraph className='p-2' style={{fontSize:"2.5rem",backgroundColor:"#FA5A7D",borderRadius:"50px",color:"white"}} /> 
                               </div>
                                <h6>550</h6>
                                <h6 style={{color:"#425166"}}>Total Leads</h6>
                                <h6 style={{color:"#4079ED"}}>+8% from yesterday</h6>
                            </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 p-5 pb-4 mb-2">
                           <div className="card"style={{borderRadius:"20px",backgroundColor:"#FFF4DE", border:"none"}}>
                            <div className="card-body">
                               <div className="pb-2">
                               <FaRegFileAlt  className='p-2' style={{fontSize:"2.5rem",backgroundColor:"#FF947A",borderRadius:"50px",color:"white"}} /> 
                               </div>
                                <h6>50</h6>
                                <h6 style={{color:"#425166"}}>Total Admissons</h6>
                                <h6 style={{color:"#4079ED"}}>+5% from yesterday</h6>
                            </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 p-5 pb-4 mb-2">
                           <div className="card"style={{borderRadius:"20px",backgroundColor:"#DCFCE7",border:"none"}}>
                            <div className="card-body">
                               <div className="pb-2">
                               <MdOutlineLocalOffer className='p-2' style={{fontSize:"2.5rem",backgroundColor:"#3CD856",borderRadius:"50px",color:"white"}} /> 
                               </div>
                                <h6>5</h6>
                                <h6 style={{color:"#425166"}}>Total Associates</h6>
                                <h6 style={{color:"#4079ED"}}>+1.2% from yesterday</h6>
                            </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 p-5 pb-4 mb-2">
                           <div className="card"style={{borderRadius:"20px",backgroundColor:"#F3E8FF",border:"none"}}>
                            <div className="card-body">
                               <div className="pb-2">
                               <FaUserPlus className='p-2' style={{fontSize:"2.5rem",backgroundColor:"#BF83FF",borderRadius:"50px",color:"white"}} /> 
                               </div>
                                <h6>20</h6>
                                <h6 style={{color:"#425166"}}>Total Users </h6>
                                <h6 style={{color:"#4079ED"}}>+0.5% from yesterday</h6>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
             </div>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
           <div className="card crd-shdo" style={{borderRadius:"20px",border:"none"}}>
            <div className="card-body">
           <div id="chart">
             <ReactApexChart options={options} series={series} type="line" height={350} />
           </div>
           <div id="html-dist"></div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 col-md-6 col-sm-12 mt-2 mb-4 containerr" style={{height:"54vh",overflowY:"scroll",textWrap:"nowrap"}}>
           <div className="row">
           <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center  gap-2 ">
                    <div className="">
                        <img src="https://b2912710.smushcdn.com/2912710/wp-content/uploads/2023/10/IMAG15-cover-1024x723.png?lossy=2&strip=1&webp=1" alt="" style={{width:"50px",borderRadius:"100%",height:"50px"}} />
                    </div>
                        <div className="">
                        <h5>Anil Yadav</h5>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Today</h6>
                        <h6 style={{color:"#666666"}}>2</h6>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Total Students</h6>
                        <h6 style={{color:"#666666"}}>15</h6>
                        </div>
                    </div> <hr />
           <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center  gap-2 ">
                    <div className="">
                        <img src="https://b2912710.smushcdn.com/2912710/wp-content/uploads/2023/10/IMAG15-cover-1024x723.png?lossy=2&strip=1&webp=1" alt="" style={{width:"50px",borderRadius:"100%",height:"50px"}} />
                    </div>
                        <div className="">
                        <h5>Anil Yadav</h5>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Today</h6>
                        <h6 style={{color:"#666666"}}>2</h6>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Total Students</h6>
                        <h6 style={{color:"#666666"}}>15</h6>
                        </div>
                    </div> <hr />
           <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center  gap-2 ">
                    <div className="">
                        <img src="https://b2912710.smushcdn.com/2912710/wp-content/uploads/2023/10/IMAG15-cover-1024x723.png?lossy=2&strip=1&webp=1" alt="" style={{width:"50px",borderRadius:"100%",height:"50px"}} />
                    </div>
                        <div className="">
                        <h5>Anil Yadav</h5>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Today</h6>
                        <h6 style={{color:"#666666"}}>2</h6>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Total Students</h6>
                        <h6 style={{color:"#666666"}}>15</h6>
                        </div>
                    </div> <hr />
           <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center  gap-2 ">
                    <div className="">
                        <img src="https://b2912710.smushcdn.com/2912710/wp-content/uploads/2023/10/IMAG15-cover-1024x723.png?lossy=2&strip=1&webp=1" alt="" style={{width:"50px",borderRadius:"100%",height:"50px"}} />
                    </div>
                        <div className="">
                        <h5>Anil Yadav</h5>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Today</h6>
                        <h6 style={{color:"#666666"}}>2</h6>
                        </div>
                    </div>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="d-flex justify-content-between">
                        <h6 style={{marginLeft:"10%",color:"#666666"}}>Total Students</h6>
                        <h6 style={{color:"#666666"}}>15</h6>
                        </div>
                    </div> <hr />
           </div>
           </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 col-sm-12 col-6 text-center">
          <Button className='bg-gr1 py-3 px-5' style={{borderRadius:"20px"}} variant="contained">Course</Button>

          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-6 text-center">
          <Button className='bg-gr2 py-3 px-5' style={{borderRadius:"20px"}} variant="contained">Course</Button>

          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-6 text-center pt-2">
          <Button className='bg-gr3 py-3 px-5' style={{borderRadius:"20px"}} variant="contained">Course</Button>

          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-6 text-center pt-2">
          <Button className='bg-gr4 py-3 px-5' style={{borderRadius:"20px"}} variant="contained">Course</Button>

          </div>
          </div>
      {/* </div> */}
      </div>
    </>
  )
}

export default Dashbord
