import React, { useState } from "react";
import { FaSearch, FaChevronDown } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { FaBoxOpen } from "react-icons/fa6";
import { Card,Collapse, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is included
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiMiniLink } from "react-icons/hi2";
import { FiUpload } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import Button from '@mui/material/Button';
import { Box, Modal } from "@mui/material";
import { GiCancel } from "react-icons/gi";
// import {Button } from 'react-bootstrap';

const Rewards = () => {
  const [open, setOpen] = useState(null);
  const [show, setShow] = useState(false);
  const [openn, setOpenn] = useState(null);
  const [showw, setShoww] = useState(false);
  const [activeButton, setActiveButton] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [opend, setOpennn] = useState(false);

  const handleOpennn = () => setOpennn(true);
  const handleClosee = () =>setOpennn(false);
  const toggleAccordion = (index) => {
    setOpen(open === index ? null : index);
  };
  const renderInputs = () => {
    switch (activeButton) {
      case 'link':
        return (
          <>
            <Form.Control type="text" placeholder="Enter Task..." className="mt-2" />
            <Form.Control type="text" placeholder="Enter URL" className="mt-2" />
          </>
        );
      case 'upload':
        return (
          <>
            <Form.Control type="text" placeholder="Enter Task..." className="mt-2" />
            <Form.Control type="file" className="mt-2" />
          </>
        );
      case 'conversion':
        return (
          <>
            <Form.Control type="text" placeholder="Enter Task..." className="mt-2" />
            <Form.Control type="text" placeholder="Enter No." className="mt-2" />
          </>
        );
      case 'share':
        return (
          <>
            <Form.Control type="text" placeholder="Enter Task..." className="mt-2" />
            <Form.Control type="text" placeholder="Share" className="mt-2" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-2 col-md-6 col-sm-12"></div> */}
            <div className="col-lg-12 col-md-6 col-sm-12">             
              <div className="row d-flex sticky-top p-2" style={{backgroundColor:"white"}}>
              <div className="col-lg-6 mt-3 pt-2">
                  <h4 style={{color:"#66666",fontWeight:"600"}}>Rewards</h4>
                </div>


                <div className="col-lg-6 col-md-6 col-sm-12 d-flex p-1 mt-3  gap-3">
                   <div className="d-flex align-items-center p-2" style={{ border: "1px solid grey", width:"80%", borderRadius:"5px" }}>
                    <FaSearch className="search-icon"/>
                    <input className='d-flex w-100' style={{  outline: "none", border: "none" ,fontFamily:"sans-serif",}} type="text" name='text' placeholder='Search Dashbord' onFocus={(e) => { e.target.style.border = 'none'; e.target.style.outline = 'none'; e.target.style.boxShadow = 'none'; }}/>
                  </div>  
                   <div style={{textAlign:"end"}}>
                   

                   <Button onClick={handleOpennn} variant="contained">Add Level</Button>
           
                   <Modal open={opend} onHide={handleClosee} aria-labelledby="modal-title" aria-describedby="modal-description">
       <Box className='mt-5' style={{ borderRadius: '20px', outline: 'none' }}>
           <div className="row d-flex justify-content-center">
               <div className="col-lg-4 col-md-6 col-sm-12 p-5" style={{ backgroundColor: "#616CD7", borderRadius: "10px" }}>
                   
                   {/* Close Button */}
                   <div className="d-flex justify-content-end">
                       <button
                           onClick={handleClosee}
                           style={{
                               backgroundColor: 'transparent',
                               border: 'none',
                               color: '#fff',
                               fontSize: '1.5rem',
                               cursor: 'pointer',
                           }}
                       >
                           &times;
                       </button>
                   </div>
   
                   <Form.Group onSubmit={handleClosee} controlId="formTask">
                       <div className="row">
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}> Level No.</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="Enter No." />
                               </Form.Group>
                           </div>
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}>Level Name</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="Level Name" />
                               </Form.Group>
                           </div>
                       </div>
                       <p className="mt-2" style={{ color: "#fff" }}>Add New Task</p>
                       <div className="task-input-container">
                           <input type="text" placeholder="Enter Task" className="task-input" />
                           <div className="icons-container">
                               <button className="icon-button">
                                   <HiMiniLink />
                                   <i className="icon-link"></i>
                               </button>
                               <button className="icon-button">
                                   <FiUpload />
                                   <i className="icon-upload"></i>
                               </button>
                               <button className="icon-button">
                                   <AiOutlinePlus />
                                   <i className="icon-add"></i>
                               </button>
                           </div>
                       </div>
   
                       <div className="row">
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}> Level Name</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="Enter Name" />
                               </Form.Group>
                           </div>
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}>Upload Image</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="Upload Image" />
                               </Form.Group>
                           </div>
                       </div>
                       <div className="row">
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}>Enter Amount</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="Tablet" />
                               </Form.Group>
                           </div>
                           <div className="col-6">
                               <Form.Group controlId="formReward" className="mt-3">
                                   <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                                       <span style={{ fontWeight: "400" }}>Date</span>
                                   </Form.Label>
                                   <Form.Control type="text" placeholder="1st July 2024" />
                               </Form.Group>
                           </div>
                       </div>
                       <Button
                           onClick={handleClosee}
                           className="mt-3"
                           type="submit"
                           style={{
                               backgroundColor: '#23D406',
                               width: '100%',
                               border: '1px',
                               borderRadius: '8px',
                               gap: '10px',
                           }}
                       >
                           <span style={{ fontWeight: "500", color: "#fff" }}>Upload</span>
                       </Button>
                   </Form.Group>
               </div>
           </div>
       </Box>
   </Modal>
   
                                           
                   </div> 

                </div>

              </div>
              {/* <div className=" scroll" style={{height:"85vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap'}}> */}

              <div className="accordion-container mt-4 mb-5 pb-5">
                
                {/* Accordion :1 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"  onClick={() => toggleAccordion(1)}> 
                    <div className="">
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 1:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 1 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                      
                    </div>
                  </Card.Header>
                  <Collapse in={open === 1}>
                    <div id="collapse-text-1">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <div>
          mukesh
         </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                {/* Accordion :2 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"   onClick={() => toggleAccordion(2)}>
                    <div className="">
                   
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 2:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 2 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                      
                    </div>
                  </Card.Header>
                  <Collapse in={open === 2}>
                    <div id="collapse-text-2">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <div>
          mukesh
         </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                {/* Accordion :3 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"   onClick={() => toggleAccordion(3)}>
                    <div className="">
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 3:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 3 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                  
                    </div>
                  </Card.Header>
                  <Collapse in={open === 3}>
                    <div id="collapse-text-3">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <div>
          mukesh
         </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                {/* Accordion :4 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"   onClick={() => toggleAccordion(4)}>
                    <div className="">
                   
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 4:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 4 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                  
                    </div>
                  </Card.Header>
                  <Collapse in={open === 4}>
                    <div id="collapse-text-4">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <div>
          mukesh
         </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                
                {/* Accordion :5 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"   onClick={() => toggleAccordion(5)}>
                    <div className="">
                   
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 5:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 5 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                    </div>
                  </Card.Header>
                  <Collapse in={open === 5}>
                    <div id="collapse-text-1">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                                
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton   style={{backgroundColor:"#6b6bff"}}>
        </Offcanvas.Header>
        <Offcanvas.Body style={{backgroundColor:"#6b6bff"}}>
          <div>
<Form.Group controlId="formTask">
              <div style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Edit Current task</span>
                </div>
              <Form.Control as="select">
                <option>
                <div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1.
                            </div>
                            <span className="ml-2">
                              <b> Host a webinar.</b>
                            </span>
                          </div>
                </option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2.
                            </div>
                            <span className="ml-2">
                              <b> Write a blog post.</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3.
                            </div>
                            <span className="ml-2">
                              <b> Share your experiences</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4.
                            </div>
                            <span className="ml-2">
                              <b> Mentor others</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5.
                            </div>
                            <span className="ml-2">
                              <b> Lead a project</b>
                            </span>
                          </div></option>
              </Form.Control>



              <div className="d-flex flex-column mt-2">
      <div className="d-flex justify-content-between" style={{gap:"10px"}}>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('link')}>
          <HiMiniLink /> Link
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('upload')}>
          <FiUpload /> Upload
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('conversion')}>
          Conversion
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('share')}>
          <IoShareSocialOutline /> Share
        </Button>
      </div>
      {renderInputs()}
    </div>



            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group controlId="formReward" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
               <span style={{fontWeight:"400"}}> Reward Name</span>
              </Form.Label>
              <Form.Control type="text" placeholder="Tablet" />
            </Form.Group>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group controlId="formUploadImage" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Upload Image </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Doc_image.p" disabled />
            </Form.Group>
              </div>
            </div>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Amount</span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Amount" />
            </Form.Group>
            <Form.Group controlId="formRemark" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
              <span style={{fontWeight:"400"}}>Remark</span>
                 <span className="required">*</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter Remark" />
            </Form.Group>

            <Button
              onClick={handleClose}
              className="mt-3"
              type="submit"
              style={{
                backgroundColor: '#23D406',
                width: '100%',
                border: '1px',
                borderRadius: '10px',
                gap: '10px',
                color:"#fff"
              }}
            >
              <span style={{fontWeight:"500"}}>Save</span>
            </Button>
            </Form.Group>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                {/* Accordion :6 */}
                <Card className="accordion-card border m-2">
                  <Card.Header className="accordion-header"   onClick={() => toggleAccordion(6)}>
                    <div className="">
                   
                          <div className="row">
                            <div className="d-flex" style={{cursor:"pointer"}}>
                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex" style={{textAlign:"center"}}>
                             
                              <div className="" style={{textAlign:"start"}}>
                              <FaBoxOpen className="mt-2 " style={{ color: "#F1AD02", fontSize: "30px",marginRight:"10px"}}/>
                                </div>
                                
                                <div className="" style={{textAlign:"start"}}>
                         <h6 className="mt-2" style={{lineHeight:"10px"}}>Level 6:Element</h6>
                      <p className="" style={{fontSize:"12px",color:"#B4B4B4"}}>You can Reach a Great Milestone! Celebrate Your Victory.</p>
                                </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:"end"}}>
                            <FaChevronDown 
                              className={`accordion-icon ${
                                open === 6 ? "rotate" : ""
                              }`} 
                            />
                            </div>
                            </div>
                          </div>
                    </div>
                  </Card.Header>
                  <Collapse in={open === 6}>
                    <div id="collapse-text-1">
                      <Card.Body className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-end">
                            <button style={{ border: "none", backgroundColor: "transparent" }}>
                              <CiEdit onClick={handleShow} style={{ fontSize: "22px", fontWeight: "800" }} />
                                
                              <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton   style={{backgroundColor:"#6b6bff"}}>
        </Offcanvas.Header>
        <Offcanvas.Body style={{backgroundColor:"#6b6bff"}}>
          <div>
<Form.Group controlId="formTask">
              <div style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Edit Current task</span>
                </div>
              <Form.Control as="select">
                <option>
                <div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1.
                            </div>
                            <span className="ml-2">
                              <b> Host a webinar.</b>
                            </span>
                          </div>
                </option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2.
                            </div>
                            <span className="ml-2">
                              <b> Write a blog post.</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3.
                            </div>
                            <span className="ml-2">
                              <b> Share your experiences</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4.
                            </div>
                            <span className="ml-2">
                              <b> Mentor others</b>
                            </span>
                          </div></option>
                <option><div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                            <div
                              style={{
                                backgroundColor: "green",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "solid",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5.
                            </div>
                            <span className="ml-2">
                              <b> Lead a project</b>
                            </span>
                          </div></option>
              </Form.Control>



              <div className="d-flex flex-column mt-2">
      <div className="d-flex justify-content-between" style={{gap:"10px"}}>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('link')}>
          <HiMiniLink /> Link
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('upload')}>
          <FiUpload /> Upload
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('conversion')}>
          Conversion
        </Button>
        <Button variant="light" className="border" style={{backgroundColor

          :"#fff"
        }} onClick={() => setActiveButton('share')}>
          <IoShareSocialOutline /> Share
        </Button>
      </div>
      {renderInputs()}
    </div>



            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group controlId="formReward" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
               <span style={{fontWeight:"400"}}> Reward Name</span>
              </Form.Label>
              <Form.Control type="text" placeholder="Tablet" />
            </Form.Group>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group controlId="formUploadImage" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Upload Image </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Doc_image.p" disabled />
            </Form.Group>
              </div>
            </div>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
                <span style={{fontWeight:"400"}}>Amount</span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Amount" />
            </Form.Group>
            <Form.Group controlId="formRemark" className="mt-3">
              <Form.Label style={{ color: '#fff', fontWeight: '200' }}>
              <span style={{fontWeight:"400"}}>Remark</span>
                 <span className="required">*</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter Remark" />
            </Form.Group>

            <Button
              onClick={handleClose}
              className="mt-3"
              type="submit"
              style={{
                backgroundColor: '#23D406',
                width: '100%',
                border: '1px',
                borderRadius: '10px',
                gap: '10px',
                color:"#fff"
              }}
            >
              <span style={{fontWeight:"500"}}>Save</span>
            </Button>
            </Form.Group>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
                            </button>
                          </div>
                        </div>
                        <div style={{ textAlign: "start", border: "none", marginLeft: "20px" }}>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              1
                            </div>
                            <span className="ml-2">
                              <b>Host a webinar.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              2
                            </div>
                            <span className="ml-2">
                              <b>Write a blog post.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              3
                            </div>
                            <span className="ml-2">
                              <b>Share your experiences.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px",gap:"5px" }}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              4
                            </div>
                            <span className="ml-2">
                              <b>Mentor others.</b>
                            </span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "7px" ,gap:"5px"}}>
                            <div
                              style={{
                                backgroundColor: "#23D406",
                                borderRadius: "50px",
                                color: "#fff",
                                border: "none",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              5
                            </div>
                            <span className="ml-2">
                              <b>Lead a project.</b>
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rewards;
