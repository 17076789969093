import React, { useEffect, useState } from 'react';
import { FaChevronRight, FaRegUserCircle } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import '../Associates/Associate.css';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../env'
import { Pagination, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const Associatess = () => {
  const Navigate = useNavigate()
  
const isDesktop= useMediaQuery({
  query: '(min-width: 768px)'
})
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

 

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [data, setdata] = useState([])
  const User = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);


      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/admin/associate/list?page=${currentPage}`, requestOptions)
      const result = await response.json();
      console.log(result, "llll")
      if (response.status == 200) {
        setdata(result.users);
        setTotalPages(result.totalPages)
      } else {
        console.error("Unexpected response status:", result.status);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    User()
  }, [currentPage])

  
  return (

    <>

      <div className="container mb-5 pb-5">
       <div className="row sticky-top pt-2" style={{backgroundColor:"white"}}>
       <div className="col-lg-4 pt-4 mt-1">
          <h4 className={`${isDesktop? "":"text-center"}`} style={{color:"#66666",fontWeight:"600"}} >Associates</h4>
        </div>
        <div className='col-lg-3 col-md-12 col-sm-12'></div>
       <div className="col-lg-5 col-md-12 col-sm-12 pt-4">
        <div className=' justify-content-end'>
          <div className="border d-flex gap-3 p-2 " style={{ borderRadius: "6px" }}>
            <div className="">
              <h6><CiSearch className='mt-2' style={{ fontSize: "18px" }} /></h6>
            </div>
            <input className='d-flex' style={{ width: "100%", outline: "none", border: "none", fontFamily: "sans-serif", color: "#B1AFC8" }} type="text" name='text' placeholder='Search Associate' onFocus={(e) => { e.target.style.border = 'none'; e.target.style.outline = 'none'; e.target.style.boxShadow = 'none'; }} />
          </div>
          </div>
        </div>
        
       </div>
        
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-12">
          {/* <div className=" scroll" style={{height:"70vh", overflowX: 'scroll', overflowY: 'auto', textWrap: 'nowrap' }}> */}
          <div className="row mt-3 mb-4 ">
              {data?.map((res) => {
                return (
                  <div key={res.id} className="col-lg-4 col-md-6 col-sm-12 mt-2"
                    onClick={() => Navigate(`/associate/profile/${res.id}`)}
                  >
                    <div className="card shadow bg-white" style={{ borderRadius: "10px", cursor: "pointer" }}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-3">
                            {
                              res.profile_pic ? <>

                                <img src={`https://iccdev.tipsg.in${res.profile_pic}`} alt="Profile" style={{ width: "70px", borderRadius: "100px",height:'70px' }} />
                              </> : <>

                                <h1><FaRegUserCircle /></h1>
                              </>
                            }
                          </div>
                          <div className="col-9">
                            <h5 style={{ fontSize: "20px", fontFamily: "sans-serif" }} className='text-truncate'>
                              {res.full_name} <FaChevronRight style={{ color: "#ABABAB", marginLeft: "15px" }} />
                            </h5>
                            <div className="row">
                              <div className="col-5">
                                <h6 style={{ color: "#8A8A8A", fontFamily: "sans-serif", marginRight: "10px" }} className='text-truncate'>
                                  {res.username}
                                </h6>
                              </div>
                              <div className="col-7 d-flex align-items-center gap-2">
                                <h6
                                  className="p-1 px-2 d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#E3FFF3",
                                    color: "#2E8760",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    fontFamily: "sans-serif",
                                    marginLeft: "17px"
                                  }}
                                >
                                  {res.organisation_name}
                                </h6>
                              </div>
                            </div>
                            <div className="d-flex gap-4" style={{ alignItems: "center" }}>
                              <h5 className="ml-2" style={{ fontSize: "12px", color: "#737791" }}>
                                {/* Assuming you want to display some data here */}
                                {/* res.total and res.last don't seem to be in the provided response, replace with relevant fields */}Total uploaded Doc: 0
                              </h5>
                              {
                                res.plan_status? <>
                                <h6 className="Background-image text-white text-center"
                                style={{ fontSize: "10px", fontWeight: "bolder", marginLeft: "10px" }}>ICC
                              </h6>
                                </>:<></>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
           
              <div className='p-4'>
  <Stack spacing={2} className="d-flex justify-content-center align-items-center"
    style={{position: "fixed",bottom: "10px",left: "0",width: "100%"}}>
    <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} style={{backgroundColor:"#fff",border:"2px",borderRadius:"14px"}} />
  </Stack>
</div>




            </div>
          {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Associatess;
