import React from 'react'
import { Form, Button } from "react-bootstrap";
import "../App.css";
const Formm = () => {
  return (
    <>
    <section>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'></div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-4' >
                <Form className="custom-form"  style={{backgroundColor:"#133BBE"}}>
                <Form.Group controlId="formCourse">
                <Form.Label style={{color:"#fff",fontWeight:"200",marginRight:"250px"}}>Course<span className="required">*</span></Form.Label>
                <Form.Control as="select" placeholder="Select The Course">
                <option>Select The Course</option>
                </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formFees">
              <div className='row'>
                <div className='col-lg-6 col-md-12'>
                <Form.Label style={{color:"#fff",fontWeight:"200",marginRight:"100px"}}>Fees</Form.Label>
                <Form.Control type="text" placeholder="Total Amount" />
                </div>
                <div className='col-lg-6 col-md-12'>
                <Form.Label style={{color:"#fff",fontWeight:"200",marginRight:"80px"}}>Discount<span className="required">*</span></Form.Label>
                <Form.Control type="text" placeholder="in %" />
                </div>
              </div>
            </Form.Group>
               <Form.Group controlId="formEMI">
              <Form.Label style={{color:"#fff",fontWeight:"200" ,marginRight:"230px"}}>No. of EMI<span className="required">*</span></Form.Label>
              <Form.Control type="text" placeholder='Upload Doc' />
            </Form.Group>
            <Form.Group controlId="formEMIAmount">
              <Form.Label style={{color:"#fff",fontWeight:"200" ,marginRight:"220px"}}>EMI Amount<span className="required">*</span></Form.Label>
              <Form.Control type="text" placeholder="Enter Amount" />
            </Form.Group>
            <Form.Group controlId="formRemark">
              <Form.Label style={{color:"#fff",fontWeight:"200" ,marginRight:"250px"}}>Remark<span className="required">*</span></Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter Remark" />
            </Form.Group>
            <Button className='mt-2 mb-4' style={{backgroundColor:"#23D406",width:"100%",border:"1px",borderRadius:"10px",gap:"10px"}} type="submit">
              Save
            </Button>
                  </Form>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Formm