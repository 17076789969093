import React, { useEffect, useState } from 'react';
import profile2 from '../assets/Associateprofilwe.png';
import file from '../assets/credit-card-fill1.png';
import eye from '../assets/solar_eye-linear.png';
import icc from '../assets/yellowicc.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { IoCallOutline } from "react-icons/io5";
import { FaCalendarAlt } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { LuSchool } from 'react-icons/lu';
import ProgressBar from './ProgressBar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Form} from "react-bootstrap";
import './Associate.css';
import { Button } from '@mui/material';
import { baseUrl } from '../../env';
import { Toaster, toast } from 'sonner'
const AssociatesProfile1 = () => {

    const { id } = useParams()

    const Navigate = useNavigate()
    const [tabState, setTabState] = useState(0);

    const data = [
        {
            Sno: "1.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "2.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "3.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "4.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "5.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "6.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "7.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "8.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "9.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },

        {
            Sno: "10.",
            File: "xyz Student",
            TotalData: "500",
            PendingData: "150",
            date: "Jun 16, 2024",
        },


    ];
    const data1 = [
        {
            Sno: "1.",
            Name: "Mukesh Meena",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 1
        },
        {
            Sno: "2.",
            Name: "deepak Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },
        {
            Sno: "3.",
            Name: "Ankit Kumar",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 1
        },
        {
            Sno: "4.",
            Name: "Ankit Jatt",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },
        {
            Sno: "5.",
            Name: "Harsh Sharma",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 1
        },
        {
            Sno: "6.",
            Name: "Abhi Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },
        {
            Sno: "7.",
            Name: "Ashok Sharma",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 1
        },
        {
            Sno: "8.",
            Name: "Abhinav Nagar",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },
        {
            Sno: "9.",
            Name: "Rajesh Jaat",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 1
        },
        {
            Sno: "10.",
            Name: "Kuldeep Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },
        {
            Sno: "11.",
            Name: "J.P Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            Status: 0
        },


    ];
    const data2 = [
        {
            Sno: "1.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "2.",
            Name: "Mukesh Meena",
            Students: "53",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "3.",
            Name: "Mukesh Meena",
            Students: "35",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "4.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "5.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "6.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "7.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "8.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "9.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
        {
            Sno: "10.",
            Name: "Mukesh Meena",
            Students: "5",
            Amount: "₹25000",
            date: "Jun 16, 2024",
        },
    ];
    const data3 = [
        {
            Sno: "1.",
            Name: "Mukesh Meena",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date:"Jun 16, 2024",
        },
        {
            Sno: "2.",
            Name: "deepak Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "3.",
            Name: "Ankit Kumar",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "4.",
            Name: "Ankit Jatt",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "5.",
            Name: "Harsh Sharma",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "6.",
            Name: "Abhi Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "7.",
            Name: "Ashok Sharma",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "8.",
            Name: "Abhinav Nagar",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "9.",
            Name: "Rajesh Jaat",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "10.",
            Name: "Kuldeep Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date: "Jun 16, 2024"
        },
        {
            Sno: "11.",
            Name: "J.P Choudhary",
            Contact: "9856412330",
            Class: "12th",
            Course: "Full stack",
            date:"Jun 16, 2024"
        },


    ];
    const data5 = [
        {
            Level: "1.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Level: "2.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Level: "3.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Level: "4.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Level: "5.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Level: "6.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Level: "7.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Level: "8.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Level: "9.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Level: "10.",
            Task: "5/5",
            date: "Jun 16, 2024",
            Status:0,
        },


    ];
    const data4 = [
        {
            Sno: "1.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Sno: "2.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Sno: "3.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:2,
        },

        {
            Sno: "4.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Sno: "5.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Sno: "6.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:2,
        },

        {
            Sno: "7.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Sno: "8.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:1,
        },

        {
            Sno: "9.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:0,
        },

        {
            Sno: "10.",
            Amount: "300",
            date: "Jun 16, 2024",
            Status:0,
        },


    ];
    const getFirstCapitalLetter = (name) => {
        return name.charAt(0).toUpperCase();
    };
    const capitalizeFirstLetter = str => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = useState(false);

    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 370,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 5,
      outline: 'none',
    };
  
//   const [planId,setplanId]=useState()
//   const [userId,setuserId]=useState()


  const get_id = async () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("icc_token")}`);
    
    const raw = JSON.stringify({
      "planId": "1",
      "userId": String(id)
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    try {
     const response= await fetch(`${baseUrl}/admin/associate/activate-by-id`, requestOptions)
     const result = await response.json();
     if (result.success) {
        toast.success(result.result.message,{
         autoClose:3000

        });
     }else{
        toast.error(result.error)
     }

    } catch (error) {
      console.error(error);

    }
   
  };

  const [users,setUsers] = useState({})

  const profile_id = async () => {
  
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization",`Bearer ${localStorage.getItem("icc_token")}`);


        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"

        };

        const response = await fetch(`${baseUrl}/admin/associate/${id}`, requestOptions)
        const result = await response.json()
        console.log(result, "success")
        if(result.success == true ) {
            setUsers(result.data)

        }
        else {
            console.error("Unexpected response status:", result.success);
          }
    } catch (error) {
        console.log(error)
    }

  };
useEffect(() => {
    console.log("era")
profile_id()
}, [])

console.log(users)
 

return (
    <div>
    
    <Toaster richColors position="top-right" visibleToasts='1' />
    
    <div className="container p-3 sticky-top" style={{backgroundColor:"white"}}>
        <div className="row">
            <div className='d-flex justify-content-between align-items-center'>
                <div>  
                <h2 onClick={() => Navigate('/associate')}><IoMdArrowRoundBack className='p-1' style={{ backgroundColor: "#F1F5F9", borderRadius: "50px", cursor: "pointer" }} /></h2>
                </div>
                <div className='d-flex justify-content-center align-items-center gap-3'>
                <div className="">
                {
                    users.plan_status===false ?<>

                <Button onClick={handleOpen1} variant="contained" style={{backgroundColor:"green"}}>Activate plan</Button>
                    </>:<></>
                }
                </div>
                <div className="">
                {
                    users.plan_status===true ?<>
                    <img className='' src={icc} alt="" style={{width:"70px"}}/>
                    </>:<></>
                }
                </div>
                {/* <div className="">
                <h6 className='px-3 dap-1' style={{display:"flex" ,alignItems:"center", backgroundColor:"#E3FFF3", color:"#2E8760", borderRadius:"8px", fontWeight:"400"}}><LuSchool className='me-2' /> Prince IMS</h6>
                </div> */}
                </div>
            </div>
        </div>
    </div>


    <div className="container" style={{ fontFamily: "sans-serif"}}>
        <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="row d-flex align-items-center justify-content-center ">
                    {/* {id} */}
                    
                            <div className="col-lg-2">
                        <div className="row">
                            <div className="col-lg-2 col-md-6 col-sm-12 ">
                                <img src={`https://iccdev.tipsg.in${users.profile_pic}`} alt="" style={{ width: "180px",height:"165px", borderRadius:"100%" }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-sm-12 mt-3">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 ">
                                <h6 style={{fontSize:"18px"}}><b>{users.username}</b></h6>
                                <h6 className='ms-2'>{users.full_name}</h6>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 ">
                                <h5 style={{ color: "#455A64", fontSize: "18px" }}><IoCallOutline /> {users.contact_no}</h5>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 ">
                                <h5 style={{ color: "#455A64", fontSize: "18px" }}><FaCalendarAlt /> {users.dob}</h5>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <h6 className='text-truncate' style={{ color: "#455A64", fontSize: "18px"}}><HiOutlineMail /> {users.email}</h6>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-1">
                                    <h6 style={{ fontSize: "18px" }}> <span className='text-white px-2 py-1' style={{ backgroundColor: "black", borderRadius: "100%", fontSize: "12px" }}>1</span> Slab (<span style={{ fontSize: "14px" }}>1 Lakh - 12 Lakh</span>)</h6>
                                    <h6 style={{ fontSize: "12px", lineHeight: "2px", color: "#0000004D" }}> <span style={{ color: "lightskyblue" ,marginLeft:"20px"}}>10% </span>incentive on this slab</h6>
                                        <div className="">
                                        <ProgressBar progress={70} max={100} />
                                        </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <h5 style={{ fontSize: "18px" }}>  <span className='text-white px-2 py-1' style={{ backgroundColor: "black", borderRadius: "100%", fontSize: "12px" }}>2</span> Associate Target </h5>
                                    <h6 style={{ color: "#455A64" }}> <span style={{ color: "lightskyblue" }}>15 </span>(2-9) Commission: <span style={{ color: "lightskyblue" }}>13%</span></h6>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <h5 style={{ fontSize: "18px" }}> <span className='text-white px-2 py-1' style={{ backgroundColor: "black", borderRadius: "100%", fontSize: "12px" }}>3</span> Current Balance</h5>
                                    <h6 className='ms-2' style={{ color: "#455A64" }}> <span style={{ color: "lightskyblue" }}>₹</span> {users.wallet_balance}</h6>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <h5 style={{ fontSize: "18px" }}> <span className='text-white px-2 py-1' style={{ backgroundColor: "black", borderRadius: "100%", fontSize: "12px" }}>4</span> Pending Balance</h5>
                                    <h6 className='ms-2'><span style={{ color: "lightskyblue" }}>₹</span> 00</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><hr />
            </div>
        </div>
    </div>

    

    
        <div className="container" style={{backgroundColor:"white"}}>
                <div className="scroll row " style={{ overflowX: "scroll", textWrap: "nowrap", fontFamily: "sans-serif" }}>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-md-6 col-sm-12 d-flex justify-content-between gap-4  ">
                        <button className='btn px-5 py-1' style={{ border: tabState === 0 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 0 ? 'white' : "#94A3B8", backgroundColor: tabState === 0 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(0)}>Data</button>
                        <button className='btn px-4 py-1' style={{ border: tabState === 1 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 1 ? 'white' : "#94A3B8", backgroundColor: tabState === 1 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(1)}>Counseling</button>
                        <button className='btn px-4 py-1' style={{ border: tabState === 2 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 2 ? 'white' : "#94A3B8", backgroundColor: tabState === 2 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(2)}>Associates</button>
                        <button className='btn px-4 py-1' style={{ border: tabState === 3 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 3 ? 'white' : "#94A3B8", backgroundColor: tabState === 3 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(3)}>Admissions</button>
                        <button className='btn px-4 py-1' style={{ border: tabState === 4 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 4 ? 'white' : "#94A3B8", backgroundColor: tabState === 4 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(4)}>Withdrawals</button>
                        <button className='btn px-4 py-1' style={{ border: tabState === 5 ? 'none' : "1px solid", borderRadius: "20px", color: tabState === 5 ? 'white' : "#94A3B8", backgroundColor: tabState === 5 ? "#0047E1" : 'transparent' }} onClick={() => setTabState(5)}>Rewards</button>
                    </div>
                </div>
        </div>

    {
        tabState === 0 ?
        <>
        <section>
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                        <div className="scroll" style={{height:"55vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                            <div className="">
                                <table className="table border" style={{ borderRadius: "8px" }}>
                                    <thead className='border'>
                                        <tr>
                                            <th className='text-center' style={{ fontFamily: "sans-serif" }}>S.no</th>
                                            <th style={{ fontFamily: "sans-serif" }}></th>
                                            <th style={{ fontFamily: "sans-serif" }}>File</th>
                                            <th style={{ fontFamily: "sans-serif" }}>Total Data</th>
                                            <th style={{ fontFamily: "sans-serif" }}>Pending Data</th>
                                            <th style={{ fontFamily: "sans-serif" }}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#3C3C3C", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>  {row.Sno}</td>
                                                    <td className='pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#3C3C3C", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}> <img src={file} alt="" /> </td>
                                                    <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030202", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.File}</td>
                                                    <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#23D406", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.TotalData}</td>
                                                    <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#FF3B30", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.PendingData}</td>
                                                    <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.date}</td>
                                                    <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif",cursor:"pointer" }} onClick={()=> Navigate('/Data') } ><img src={eye} alt=""  /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </> : <></>
    }

    {
        tabState === 1 ?
        <>
        <section>
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                        <div className=" scroll" style={{height:"55vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                            <div className="">
                                <table className="table border" style={{ borderRadius: "8px" }}>
                                    <thead className='border'>
                                        <tr>
                                            <th className='text-center' style={{ fontFamily: "sans-serif" }}>S.no</th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}></th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}>Name</th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}>Contact</th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}>Class</th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}>Course</th>
                                            <th className='' style={{ fontFamily: "sans-serif" }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data1.map((row, index) => {
                                            const firstLetter = getFirstCapitalLetter(row.Name)
                                            const capitalizedName = capitalizeFirstLetter(row.Name);
                                            return (
                                                <tr key={index}>
                                                    <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>  {row.Sno}</td>
                                                    <td className='' style={{ verticalAlign: "middle", color: "#0364CF", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif", }}>
                                                        <div style={{ backgroundColor: "#E2E8F0", borderRadius: "100px", height: "50px", width: "50px", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                            <p style={{marginTop:"15px"}}>{firstLetter}</p>
                                                        </div>
                                                    </td>
                                                    <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{capitalizedName}</td>
                                                    <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Contact}</td>
                                                    <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Class}</td>
                                                    <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Course}</td>
                                                    <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: row.Status === 0 ? '#34C759' : row.Status === 1 ? '#FFCC00' : "inherit", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Status === 0 && "Done"}
                                                        {row.Status === 1 && "Pending"}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </> : <></>
    }

    {
    tabState === 2 ?
        <>
        <section>
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                        <div className="scroll" style={{ height:"55vh",overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                            <div className="">
                                <table className="table border" style={{ borderRadius: "8px" }}>
                                    <thead className='border'>
                                        <tr>
                                            <th className='text-center' style={{ fontFamily: "sans-serif" }}>S.no</th>
                                                <th style={{ fontFamily: "sans-serif" }}></th>
                                                    <th style={{ fontFamily: "sans-serif" }}>Name</th>
                                                    <th style={{ fontFamily: "sans-serif" }}>Students</th>
                                                    <th style={{ fontFamily: "sans-serif" }}>Amount</th>
                                                    <th style={{ fontFamily: "sans-serif" }}>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data2.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#3C3C3C", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Sno}</td>
                                                            <td className='pb-4 pt-4'><img src={profile2} alt="" /> </td>
                                                            <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030202", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Name}</td>
                                                            <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#23D406", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Students}</td>
                                                            <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Amount}</td>
                                                            <td className='pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.date}</td>
                                                        </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        </> : <></>
    }

    {
    tabState === 3 ?
        <>
        <section>
                    <div className="container mt-4 mb-5">
                        <div className="row">
                            <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                                <div className="scroll" style={{height:"55vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                                    <div className="">
                                        <table className="table border" style={{ borderRadius: "8px" }}>
                                            <thead className='border'>
                                                <tr>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>S.no</th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}></th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}>Name</th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}>Contact</th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}>Class</th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}>Course</th>
                                                    <th className='' style={{ fontFamily: "sans-serif" }}>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data3.map((row, index) => {
                                                    const firstLetter = getFirstCapitalLetter(row.Name)
                                                    const capitalizedName = capitalizeFirstLetter(row.Name);
                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>  {row.Sno}</td>
                                                            <td className='' style={{ verticalAlign: "middle", color: "#0364CF", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif", }}>
                                                                <div style={{ backgroundColor: "#E2E8F0", borderRadius: "100px", height: "50px", width: "50px", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                    <p style={{marginTop:"15px"}}>{firstLetter}</p>
                                                                </div>
                                                            </td>
                                                            <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{capitalizedName}</td>
                                                            <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Contact}</td>
                                                            <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Class}</td>
                                                            <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Course}</td>
                                                            <td className=' pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "400", fontSize: "16px", fontFamily: "sans-serif" }}>{row.date}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </section>
        </> : <></>
    }

    {
        tabState === 4 ?
            <>
                <section>
                    <div className="container mt-4 mb-5">
                        <div className="row">
                            <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                                <div className="scroll" style={{ height:"55vh",overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                                    <div className="">
                                        <table className="table border" style={{ borderRadius: "8px" }}>
                                            <thead className='border'>
                                                <tr>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>S.no</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Amount</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Date</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Status</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data4.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#3C3C3C", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif" }}>  {row.Sno}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030202", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Amount}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif" }}>{row.date}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: row.Status === 0 ? '#34C759' : row.Status === 1 ? '#FFCC00' : row.Status === 2 ? '#D20000' : "inherit", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Status === 0 && "Success"}
                                                                {row.Status === 1 && "Requested"} {row.Status === 2 && "Failed"}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "700", fontSize: "16px", fontFamily: "sans-serif" }} onClick={handleOpen} > <img src={eye} alt="" style={{cursor:"pointer"}} /> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box className='pt-5' style={{ borderRadius: '20px' ,outline: 'none'}}>
                            <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-12" style={{ backgroundColor:"#616CD7" , borderRadius:"10px"}}>
                                <Form className="custom-form" >
                                <div className="d-flex justify-content-end">
                                <h6 className='px-2' style={{backgroundColor:"white", borderRadius:"5.24px", color:"#2E8760"}}>Success</h6>
                                </div>
                                <Form.Group controlId="formAmount">
                                    <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '230px' }}>
                                    Amount<span className="required">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="₹50000" />
                                </Form.Group>
                                <Form.Group controlId="formName">
                                    <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '230px' }}>
                                    User Name
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Jp.Sikar 2111" />
                                </Form.Group>
                                <Form.Group controlId="formBank">
                                    <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <Form.Label style={{ color: '#fff', fontWeight: '200',fontSize:"14px", marginRight: '100px' }}>
                                        Bank Name
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Axis" />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '80px' }}>
                                        Branch
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Swej Farm" />
                                    </div>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formFees">
                                    <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '100px' }}>
                                        IFSC Code
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Axis001230" />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '80px' }}>
                                        Date
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="1st July’ 2024" />
                                    </div>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formEMIAmount">
                                    <Form.Label style={{ color: '#fff', fontWeight: '200', marginRight: '220px' }}>
                                    Account Number
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="79768 08381 9" />
                                </Form.Group>
                                </Form>
                            </div>
                            {/* <div className="col-lg-4 col-md-6 col-sm-12"></div> */}
                            </div>
                            <h5 className="text-center text-dark">X</h5>
                    </Box>
                    </Modal>
                    
            </> : <></>
    }
    {
        tabState === 5 ?
            <>
                <section>
                    <div className="container mt-4 mb-5">
                        <div className="row">
                            <div className="col-lg-12 com-md-6 col-sm-12 mb-5">
                                <div className=" scroll" style={{height:"55vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap' }}>
                                    <div className="">
                                        <table className="table border" style={{ borderRadius: "8px" }}>
                                            <thead className='border'>
                                                <tr>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Level</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Task</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Date</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}>Status</th>
                                                    <th className='text-center' style={{ fontFamily: "sans-serif" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data5.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center pb-4 pt-4' style={{ padding: "10px", verticalAlign: "middle", color: "#3C3C3C", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Level}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#030202", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Task}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: "#666666", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{row.date}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: row.Status === 0 ? '#34C759' : row.Status === 1 ? '#FFCC00' : "inherit", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Status === 0 && "Completed"}
                                                                {row.Status === 1 && "In-Progress"}</td>
                                                            <td className='text-center pb-4 pt-4' style={{ verticalAlign: "middle", color: row.Status === 0 ? '#AF52DE' : row.Status === 1 ? '#AF52DE' : "inherit", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>{row.Status === 0 && "Claimed"}
                                                            {row.Status === 1 && "-"}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>
    }

    <Modal open={open1}  aria-labelledby="modal-title" aria-describedby="modal-description" >
        <Box sx={style} style={{ borderRadius: "20px"}}>
          <div className="text-center">
            <h5 className='mb-5' style={{fontWeight:"700"}}>Do yo want to Activate Plan</h5>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-5">
          <Button onClick={()=>{
            handleClose1()
           get_id()
           }} className='px-4' variant="contained" style={{backgroundColor:"green"}}>Yes</Button>
          <Button onClick={handleClose1} variant="contained" style={{backgroundColor:"red"}}>Not Now</Button>
          </div>
        </Box>
      </Modal>

        </div>
    )
}

export default AssociatesProfile1;
