import Profile1 from "./Component/Profile1";
import Enrolled from "./Component/Enrolled";
import Associatess from "./Component/Associates/Associatess";
import Sidebar from "./Component/Sidebar";
import Headers from "./Component/Headers";
import { useMediaQuery } from 'react-responsive'
import AssociatesProfile1 from "./Component/Associates/AssociatesProfile1";
import AssociateData from "./Component/Associates/AssociateData";
import Admissons from "./Component/Admissions/Admissons";
import Login from "./Component/Login/Login";
import Leads from "./Component/Leads/Leads";
import Rewards from "./Component/Rewards/Rewards";
import Notification from "./Component/Notification/Notifaction"
import Four from "./Component/Four";
import Five from "./Component/Five";
import Six from "./Component/Six";
import Seven from "./Component/Seven";
import Formm from "./Component/Formm";
import Third from "./Component/Third";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashbord from "./Component/Dashbord/Dashbord";
import Privatee from "./Component/Private/Privatee";

function App() {

  const isMobile = useMediaQuery({
    query: '(max-width: 568px)'
  })

  const location = useLocation()
  let isLogin =  location.pathname === '/login'
  console.log(isLogin)
 

  return (
    

    <div className='container-fluid p-0 scroll' style={{height:"100vh",overflow:'hidden'}} >
      <div className='row p-0 m-0' >


{  !isLogin &&
<>
        <div className={`col-md-2 col-sm-0 p-0`} >
          {
            isMobile ? <>
            </> : <>
              <Sidebar />
            </>
          }
        </div>

        {
          isMobile? <>
            <div className="col-sm-12 sticky-sm-top" >
              <Headers />
            </div>
          </> : <></>
        }

</>
      }



        <div className= { isLogin ? "col-lg-12 col-md-12 col-sm-12" :  "col-lg-10 col-md-10 col-sm-12 scroll" } style={{height:"100vh",overflow:'auto'}}>
      

        <Routes>
         <Route index path="/login" element={<Login />} />

    <Route path="/"  element={<Privatee />}>
      <Route path="/" element={<Dashbord />} />
      <Route path="/three" element={<Third />} />
      <Route path="/four" element={<Four />} />
      <Route path="/five" element={<Five />} />
      <Route path="/six" element={<Six />} />
      <Route path="/seven" element={<Seven />} />
      <Route path="/eight" element={<Seven />} />
      <Route path="/formm" element={<Formm />} />
      <Route path="/profile1" element={<Profile1 />} />
      <Route path="/enrolled" element={<Enrolled />} />
      <Route path="/associate" element={<Associatess />} />
      <Route path="/associate/profile/:id" element={<AssociatesProfile1 />} />
      <Route path="/data" element={<AssociateData />} />
      <Route path="/admissions" element={<Admissons/>} />
      <Route path="/notification" element={<Notification/>} />
      <Route path="/leads" element={<Leads />} />
      <Route path="/rewards" element={<Rewards />} />
    </Route>
  </Routes>
        </div>



      </div>

    </div>
  );
}

export default App;
