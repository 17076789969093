import React, { useState } from 'react'
import Notifi from '../assets/Notiprofi.png'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import { IoCloudUploadOutline } from "react-icons/io5";
import { TbRosetteDiscountCheckFilled } from 'react-icons/tb';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Box } from '@mui/material';
import Form from 'react-bootstrap/Form';



const Notifaction = () => {
    const Navigate = useNavigate ()

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: 370,bgcolor: 'background.paper',boxShadow: 24,p: 5,outline: 'none',
      };

  return (
    <>
    <div className='d-flex flex-column overflow-hidden ' style={{height:"100vh" }}>
    <div className="container pt-4 p-3 flex-grow-0" style={{backgroundColor:"white",top:"-1px"}}>
        <div className="row">
          <div className="d-flex justify-content-between">
            <div>  
              <h2 onClick={() => Navigate('/')}><IoMdArrowRoundBack className='p-1' style={{ backgroundColor: "#F1F5F9", borderRadius: "50px", cursor: "pointer" }} /></h2>
            </div>
            <div className="pt-2">
                <h4>Notifications</h4>
            </div>
            <div>
            <Button variant="contained" onClick={handleOpen}>Send Message</Button>
            </div>
          </div>
        </div>
      </div>


      <div className="container mt-4 mb-5 pb-5 flex-grow-1 overflow-y-auto hide-scroll">
        <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12">
            {/* <div className=" scroll" style={{height:"85vh", overflowX: 'scroll', overflowY: 'scroll', textWrap: 'nowrap'}}> */}

                <div className="row">
                <div className="col-lg-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <img src={Notifi} alt="" />
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>Mrs. Aarya Sharma has has requested an withdrawal for Rs 5500.Mrs. </span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Wednesday at 10:42 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <IoCloudUploadOutline  style={{color:"#616CD7",fontSize:"32px"}} />
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>New Data is uploaded by Mr. Raj jain</span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Tuesday at 9:00 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <MdOutlineTipsAndUpdates style={{color:"#616CD7",fontSize:"32px"}} />
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>Update your Leads </span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Tuesday at 9:00 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <TbRosetteDiscountCheckFilled style={{color:"#23D406",fontSize:"32px"}}/>
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>Verification of File zxy is now Completed</span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Tuesday at 9:00 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <img src={Notifi} alt="" />
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>Mr. Sharma claimed reward for level 2</span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Wednesday at 10:42 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <img src={Notifi} alt="" />
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>new associate is added.</span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Wednesday at 10:42 AM</span> <hr />
                </div>
                <div className="col-11 mt-3 ms-4">
                    <div className="d-flex gap-2">
                    <div className="">
                    <TbRosetteDiscountCheckFilled style={{color:"#23D406",fontSize:"32px"}}/>
                    </div>
                        <h6 className='d-flex gap-2'> <span className='mt-2'>Verification of File zxy is now Completed </span></h6>
                    </div>
                        <span style={{marginLeft:"38px", lineHeight:"0px",color:"#A5ACB8"}} >Last Tuesday at 9:00 AM</span> <hr />
                </div>
                
                 
                </div>
                {/* </div> */}
            </div>
        </div>
      </div>


    </div>


      <Modal open={open}  aria-labelledby="modal-title" aria-describedby="modal-description"  >
        <Box sx={style} style={{ borderRadius: "20px",backgroundColor:"#616CD7" }}>
          <div className="text-white">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send To</Form.Label>
              <Form.Control type="email" placeholder="Enter Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        <div>
        <Button onClick={handleClose} className="mt-2 mb-4" type="submit" style={{ backgroundColor:"#23D406",width: "100%", border: "1px",borderRadius: "10px", gap: "10px",color:"white" }}>Send</Button>
        </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default Notifaction
