import React from 'react';

const ProgressBar = ({ progress, max }) => {
  const progressPercent = (progress / max) * 100;

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90deg, rgba(255,229,190,1) 0%, rgba(255,247,225,1) 100%)',
    borderRadius: '25px',
    padding: '5px',
    width: '100%',
    height: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    justifyContent: "space-between",
  };

  const progressStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,215,0,1) 100%)',
    borderRadius: '25px',
    height: '15px',
    width: `${progressPercent}%`,
    transition: 'width 0.5s ease-in-out',
  };

  const circleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,215,0,1) 100%)',
    borderRadius: '50%',
    height: '15px',
    width: '15px',
    color: '#fff',
    fontSize:"10px",
    fontWeight: 'bold',
    marginRight: '0px',
    marginLeft: '0px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    
  };

  const circleEndStyle = {
    ...circleStyle,
    marginRight: '0',
    marginLeft: '0px',
  };

  const textStyle = {
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '4px',
    color: '#d9a021',
    fontWeight: 'bold',
    fontSize: '12px',
    height: '20px',
  };

  return (
    <div style={containerStyle}>
      <div style={circleStyle}>1</div>
      <div style={progressStyle}></div>
      <span style={textStyle}>{`${progress}/${max}`}</span>
      <div style={circleEndStyle}>2</div>
    </div>
  );
};

export default ProgressBar;
